import React, { useEffect } from "react"

const RedirectToDogPage = () => {
  useEffect(() => {
    window.location.href = '/dogs'
  }, []);

  return (<>
    {"　"}
  </>)
}

export default RedirectToDogPage
